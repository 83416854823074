import React, { useState } from 'react'
import { Alert, Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import reportService from "../../services/reports/reports-service";
import SchemeSelectField from '../widgets/SchemeSelectField';
import { formatHttpErrorMessage } from '../common/helper_functions';

function ReportForm(props) {
    const [message, setMessage] = useState()
  const [formData, setFormData] = useState({
        "name": "",
        "start_date": null,
        "end_date": null,
        "report_type": null,
        "scheme": null,
        "subscheme": null,
        "district": ""
  })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
      e.preventDefault()
      await reportService.addReport(formData).then(response => {
         props.onSuccess()
      }).catch(error => {
          let msg = formatHttpErrorMessage(error)
          setMessage(msg)
      })
  }
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={false}
   
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter" >
        Report Form
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {message && <><Alert variant='danger'>{message}</Alert></>}
    <Form onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col md={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Report Name"
                                                name='name'
                                                
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name for
                                                a scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Start Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                name="start_date"
                                                
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid start date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    
                                    <Col md={6}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="End Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                name="end_date"
                                                
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Report Type"
                                            className=""
                                        >
                                           <Form.Select
                                                aria-label="select Fund source"
                                                name="report_type"
                                                
                                                onChange={handleChange}
                                                required
                                            >
                                                <option>Select Report</option>
                                                <option value="Family" selected="">Family Report</option>
                                                <option value="Beneficiary">Beneficiary Report</option>
                                                <option value="Claims">Claims Report</option>
                                                <option value="Policy">Policy Report</option>
                                                <option value="Offer">Offer Report</option>
                                                <option value="Payment">Payment Report</option>
                                                <option value="Invoice">Invoice Report</option>
                                            </Form.Select>
                                            
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel
                                            controlId="floatingTextarea2"
                                            label="Scheme"
                                        >
                                          <SchemeSelectField name="scheme"  onChange={handleChange} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <Button
                                            variant="primary"
                                            
                                            type="submit"
                                            className="float-end"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
        
    </Modal.Body>
    </Modal>
  )
}

export default ReportForm